<template>
    <div>
        <img :src="ImageSrc" alt="" style="width: 100%;">
    </div>
</template>

<script>
export default {
    computed: {
        ImageSrc(){
            return this.$route.type == "evaluation"? require("../../assets/device/evaluationDoc.png") : require("../../assets/device/portraitDoc.png")
        }
    }
}
</script>